import { FormGroupStructure } from 'app/forms/structure/form-group.structure';
import { FormStructureType } from 'app/forms/structure/form-structure-types.enum';
import { NavCarrierFormGroup } from 'app/forms/control/form-group';
import { ExtendedLoad } from 'shared/models/loads/extended-load.model';
import * as v from 'app/forms/control/form-validators';

export const WAYBILL_FORM = 'WAYBILL_FORM';
export const WAYBILL_FORM_STRUCTURE: FormGroupStructure = {
  type: FormStructureType.Group,
  children: {
    pieces: {type: FormStructureType.Field, initialValue: '', validators: [v.pattern(/^\d*$/)]},
    weight: {type: FormStructureType.Field, initialValue: '', validators: [v.pattern(/^\d*$/)]},
    reservationNumber: {type: FormStructureType.Field, initialValue: ''},
    bol: {type: FormStructureType.Field, initialValue: ''},
    container: {type: FormStructureType.Field, initialValue: '', validators: [v.pattern(/^[A-Z]{4}\d{6}$/)]},
    sealNumber: {type: FormStructureType.Field, initialValue: ''},
  }
};

export interface WaybillFormValue {
  pieces?: string|number;
  weight?: string|number;
  reservationNumber?: string|number;
  bol?: string;
  container?: string;
  sealNumber?: string;
}

export class WaybillForm extends NavCarrierFormGroup {
  initialize(load: ExtendedLoad) {
    if (load.stops?.length) {
      const stop = load.stops[0]; // always take the first stop

      if (stop.commodities.length > 1) {
        this.get('pieces').disable();
        this.get('weight').disable();
      }
    }
  }

  patchLoad(currentLoad: ExtendedLoad) {
    const load = new ExtendedLoad(currentLoad.toJson());
    const loadBook = load.loadBook;
    const firstStop = load.stops[0];

    // Update loadBook
    loadBook.bookingNumber = this.get('reservationNumber').value;
    loadBook.carrierEquipment.trailerNumber = this.get('container').value;

    // Update loadStop
    if (load.stops.length > 0) {
      firstStop.bolNumber = this.get('bol').value;
      firstStop.sealNumber = this.get('sealNumber').value;

      if (firstStop.commodities.length === 1) {
        load.itemTotalWeight = this.get('weight').value;
        load.itemTotalPieces = this.get('pieces').value;
      }
    }

    return load;
  }
}
